@import "src/components/ui/chalk.scss";

:root {
  --preferred-font: "Montserrat", Helvetica, Arial, sans-serif;
  --global-radius: 3px;
  --global-line-height: 1.42857143;
  --unit: 13px;
  --distancer-small: 5px;
  --distancer-medium: 10px;
  --distancer: 30px;
  --font-size: 13px;
  --font-size-tiny: 11px;
  --font-size-small: 12px;
  --font-size-normal: var(--font-size);
  --font-size-medium: 14px;
  --font-size-big: 18px;
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 600;
  --font-weight-bolder: 700;
  --h3-padding: calc(var(--distancer) / 3);
  --h3-border-width: 2px;
  --h3-height: var(--distancer);
  --h3-content-height: var(--distancer);
  --standard-increment-mobile: calc(5.6 * var(--unit));
  --standard-increment-desktop: calc(6.4 * var(--unit));

  // Chalk colors
  @each $name, $value in $colors {
    --#{$name}: #{$value};
  }

  // Color aliases
  --background-content: var(--color-gray-50);
  --color-border-gray: var(--color-gray-200);
  --color-primary: var(--color-teal-main);
  --color-warning: var(--color-orange-main);
  --color-leer-blue: var(--color-blue-main);
  --color-leer-yellow: var(--color-orange-main);
  --color-gray-light: var(--color-gray-50);
  --color-gray-medium-light: var(--color-gray-200);
  --color-gray-medium: var(--color-gray-500);
  --color-gray: var(--color-gray-800);
  --color-petrol: var(--color-gray-900);
  --color-petrol-light: var(--color-gray-800);
  --color-purple: var(--color-purple-dark);
  --color-petrol-dark: var(--color-black);
  --color-primary-dark: var(--color-teal-dark);
  --color-primary-light: var(--color-teal-light);
  --color-dark-yellow: var(--color-orange-main);
  --color-dark-red: var(--color-red-dark);

  --color-subject-DMT: var(--color-red-main);
  --color-subject-RW: var(--color-blue-main);
  --color-subject-SP: var(--color-yellow-main);
  --color-subject-TBL: var(--color-purple-main);
  --color-subject-BL: var(--color-purple-main);
  --color-subject-WST: var(--color-green-main);
  --color-subject-RvK: var(--color-blue-main);
  --color-subject-TvK: var(--color-green-main);
  --color-subject-no-subject: var(--color-gray-800);
  --color-subject-DMT-dark: var(--color-red-dark);
  --color-subject-RW-dark: var(--color-blue-dark);
  --color-subject-SP-dark: var(--color-yellow-dark);
  --color-subject-TBL-dark: var(--color-purple-dark);
  --color-subject-BL-dark: var(--color-purple-dark);
  --color-subject-WST-dark: var(--color-green-dark);
  --color-subject-RvK-dark: var(--color-blue-dark);
  --color-subject-TvK-dark: var(--color-green-dark);
  --color-subject-DMT-light: var(--color-red-light);
  --color-subject-RW-light: var(--color-blue-light);
  --color-subject-SP-light: var(--color-yellow-light);
  --color-subject-TBL-light: var(--color-purple-light);
  --color-subject-BL-light: var(--color-purple-light);
  --color-subject-WST-light: var(--color-green-light);
  --color-subject-RvK-light: var(--color-blue-light);
  --color-subject-TvK-light: var(--color-green-light);
}

/* Orientation */
@custom-media --portrait (orientation: portrait);
@custom-media --landscape (orientation: landscape);

/* Devices (defined by max width) */
@custom-media --xxs-viewport (max-width: 480px);
@custom-media --xs-viewport (max-width: 600px);
@custom-media --sm-tablet-viewport (max-width: 720px);
@custom-media --sm-viewport (max-width: 840px);
@custom-media --md-viewport (max-width: 960px);
@custom-media --lg-tablet-viewport (max-width: 1024px);
@custom-media --lg-viewport (max-width: 1280px);
@custom-media --xl-viewport (max-width: 1440px);
@custom-media --xxl-viewport (max-width: 1600px);
@custom-media --xxxl-viewport (max-width: 1920px);

/* Devices (defined by min-width) */
@custom-media --larger-than-xxs-viewport (min-width: 480px);
@custom-media --larger-than-xs-viewport (min-width: 600px);
@custom-media --larger-than-sm-tablet-viewport (min-width: 720px);
@custom-media --larger-than-sm-viewport (min-width: 840px);
@custom-media --larger-than-md-viewport (min-width: 960px);
@custom-media --larger-than-lg-tablet-viewport (min-width: 1024px);
@custom-media --larger-than-lg-viewport (min-width: 1280px);
@custom-media --larger-than-xl-viewport (min-width: 1440px);
@custom-media --larger-than-xxl-viewport (min-width: 1600px);
@custom-media --larger-than-xxxl-viewport (min-width: 1920px);

/* scss variables for @each etc */
$list-subjects: DMT, RW, SP, TBL, WST, RvK, TvK;

$subjectColors: (
  DMT: var(--color-subject-DMT),
  RW: var(--color-subject-RW),
  SP: var(--color-subject-SP),
  TBL: var(--color-subject-TBL),
  WST: var(--color-subject-WST),
  RvK: var(--color-subject-RvK),
  TvK: var(--color-subject-TvK),
  no-subject: var(--color-subject-no-subject),
);

@mixin subjectColor($property) {
  @each $subject in map-keys($subjectColors) {
    &.title-#{$subject},
    &.subject-#{$subject} {
      #{$property}: map-get($subjectColors, $subject);
    }
  }
}

.red {
  color: var(--color-dark-red);
}

.primary {
  color: var(--color-primary);
}

.disabled {
  pointer-events: none;

  opacity: 0.5;
}

.ellipsis {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.hr {
  height: 2px;
  margin-top: calc(var(--distancer) / 2);
  margin-bottom: calc(var(--distancer) / 2);

  border: 0;
  background-color: var(--color-petrol);
}

.hr--active {
  background-color: var(--color-primary);

  composes: hr;
}

.clearfix::before,
.clearfix::after {
  display: table;

  content: " ";
}

.clearfix::after {
  clear: both;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.sticky {
  z-index: 101;
}

.sticky--header {
  z-index: 102;
}

.padding-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.margin-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/*
 * Forms
 */

.form-item {
  margin-bottom: calc(var(--distancer) / 2);
}

.form-actions {
  margin-top: calc(var(--distancer) / 2);
  padding-top: calc(var(--distancer) / 2);

  border-top: 1px solid var(--color-border-gray);
}

/*
 * Table grid
 */

.table {
  display: table;

  width: 100%;
}

.table__row {
  display: table-row;

  width: 100%;
}

.table__cell,
.table__head {
  display: table-cell;

  vertical-align: top;
}

/**
 * Link
 */

.ui-link {
  text-decoration: underline;

  color: var(--color-black);
}

.ui-link:hover {
  color: var(--color-primary-dark);
}

.ui-link--muted {
  color: var(--palette-grey-600);

  composes: ui-link;
}

/**
 * A box with shadows
 */

.ui-box-with-shadow {
  border-radius: calc(2 * var(--global-radius));
  background: var(--color-white);
  box-shadow: 1px 1px 4px 0 color(var(--color-petrol) a(20%));
}

/**
 * Headings
 */

.ui-h3 {
  height: calc(var(--h3-content-height) + var(--h3-padding));
  margin: 0 0 var(--h3-padding) 0;
  padding: 0 0 var(--h3-padding) 0;

  color: var(--color-petrol);
  border-bottom: var(--h3-border-width) solid var(--color-petrol);

  font-size: var(--font-size-medium);
  line-height: var(--h3-content-height);
}

/**
 * Center growing border
 */

.ui-border-bottom-focus {
  position: relative;
}

.ui-border-bottom-focus::before,
.ui-border-bottom-focus::after {
  position: absolute;
  bottom: 0;

  left: 50%;

  width: 0;
  height: 2px;

  content: "";

  transition-timing-function: var(--animation-curve-default);
  transition-duration: 0.1s;
  transition-property: width, left, background-color;
}

.ui-border-bottom-focus::before {
  left: 0;

  width: 100%;

  background-color: transparent;
}

.ui-border-bottom-focus::after {
  background-color: var(--color-primary);
}

/**
 * DnD drop targets
 */

.dnd-target::before {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;

  content: " ";

  border: 2px dashed transparent;
  border-radius: var(--global-radius);
}

.dnd-target {
  position: relative;
}

.dnd-target--can-drop::before {
  min-height: var(--distancer);

  border-color: var(--palette-grey-400);
}

.dnd-target--can-drop--is-over::before {
  border-color: var(--color-petrol);
}

/**
 * DnD Preview element
 */

.dnd__preview {
  width: 250px;
  padding: calc(var(--distancer) / 3);

  border-radius: var(--global-radius);
  background: var(--color-white);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

.dnd__preview-title {
  padding: var(--distancer-small) 0;

  vertical-align: middle;

  color: var(--color-petrol);
  border-bottom: var(--h3-border-width) solid var(--color-primary);

  font-weight: var(--font-weight-bold);
}

.dnd__preview-grip {
  width: calc(var(--distancer) / 2);

  color: var(--color-gray-medium);
}

:export {
  @each $name, $value in $colors {
    #{$name}: #{$value};
  }
  color-border-gray: var(--color-border-gray);
  preferred-font: var(--preferred-font);
  global-radius: var(--global-radius);
  global-line-height: var(--global-line-height);
  unit: var(--unit);
  distancer-small: var(--distancer-small);
  distancer-medium: var(--distancer-medium);
  distancer: var(--distancer);
  background-content: var(--background-content);
  color-black: var(--color-black);
  color-primary: var(--color-primary);
  color-warning: var(--color-warning);
  color-leer-blue: var(--color-leer-blue);
  color-leer-yellow: var(--color-leer-yellow);
  color-gray-light: var(--color-gray-light);
  color-gray-100: var(--color-gray-100);
  color-gray-medium-light: var(--color-gray-medium-light);
  color-gray-medium: var(--color-gray-medium);
  color-gray: var(--color-gray);
  color-petrol: var(--color-petrol);
  color-petrol-light: var(--color-petrol-light);
  color-purple: var(--color-purple);
  color-petrol-dark: var(--color-petrol-dark);
  color-primary-dark: var(--color-primary-dark);
  color-primary-light: var(--color-primary-light);
  font-size: var(--font-size);
  font-size-tiny: var(--font-size-tiny);
  font-size-small: var(--font-size-small);
  font-size-normal: var(--font-size-normal);
  font-size-medium: var(--font-size-medium);
  font-size-big: var(--font-size-big);
  font-weight-thin: var(--font-weight-thin);
  font-weight-normal: var(--font-weight-normal);
  font-weight-semi-bold: var(--font-weight-semi-bold);
  font-weight-bold: var(--font-weight-bold);
  font-weight-bolder: var(--font-weight-bolder);
  color-dark-yellow: var(--color-dark-yellow);
  color-dark-red: var(--color-dark-red);
  color-subject-dmt: var(--color-subject-DMT);
  color-subject-rw: var(--color-subject-RW);
  color-subject-sp: var(--color-subject-SP);
  color-subject-tbl: var(--color-subject-TBL);
  color-subject-wst: var(--color-subject-WST);
  color-subject-rvk: var(--color-subject-RvK);
  color-subject-tvk: var(--color-subject-TvK);
  h3-padding: var(--h3-padding);
  h3-border-width: var(--h3-border-width);
  h3-height: var(--h3-height);
  h3-content-height: var(--h3-content-height);
  standard-increment-mobile: var(--standard-increment-mobile);
  standard-increment-desktop: var(--standard-increment-desktop);
  color-subject-dmt-dark: var(--color-subject-DMT-dark);
  color-subject-rw-dark: var(--color-subject-RW-dark);
  color-subject-sp-dark: var(--color-subject-SP-dark);
  color-subject-tbl-dark: var(--color-subject-TBL-dark);
  color-subject-wst-dark: var(--color-subject-WST-dark);
  color-subject-rvk-dark: var(--color-subject-RvK-dark);
  color-subject-tvk-dark: var(--color-subject-TvK-dark);
  color-subject-dmt-light: var(--color-subject-DMT-light);
  color-subject-rw-light: var(--color-subject-RW-light);
  color-subject-sp-light: var(--color-subject-SP-light);
  color-subject-tbl-light: var(--color-subject-TBL-light);
  color-subject-wst-light: var(--color-subject-WST-light);
  color-subject-rvk-light: var(--color-subject-RvK-light);
  color-subject-tvk-light: var(--color-subject-TvK-light);
}
